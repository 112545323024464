const cloudHosting = {
  1: {
    title: 'BabyGeek Cloud',
    discountPercent: 50,
    priceINR: 350,
    priceUSD: 4.69,
    featureLabel: 'Most Popular',
    features: [
      '1 vCPU',
      '2GB RAM',
      '20GB SSD Disk Space',
      'Unlimited Domain',
      'Unlimited Bandwidth',
      'Unlimited Emails',
      'Unlimited Databases',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=7'
  },
  2: {
    title: 'StartupGeek Cloud',
    discountPercent: 50,
    priceINR: 750,
    priceUSD: 10.05,
    featureLabel: 'Most Popular',
    featured: true,
    features: [
      'Best Seller',
      'DataCenter - Germany',
      '2 vCPU',
      '4GB RAM',
      '40GB SSD Disk Space',
      'Unlimited Domain',
      'Unlimited Bandwidth',
      'Unlimited Emails',
      'Unlimited Databases',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: '/contact-us',
    footerText: 'Best Value'
  },
  3: {
    title: 'AdvanceGeek Cloud',
    discountPercent: 50,
    priceINR: 1250,
    priceUSD: 16.75,
    featureLabel: 'Most Popular',
    features: [
      '2 vCPU',
      '8GB RAM',
      '80GB SSD Disk Space',
      'Unlimited Domain',
      'Unlimited Bandwidth',
      'Unlimited Emails',
      'Unlimited Databases',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=8'
  },
  4: {
    title: 'ProGeek Cloud',
    discountPercent: 50,
    priceINR: 1600,
    priceUSD: 21.44,
    featureLabel: 'Most Popular',
    features: [
      '4 vCPU',
      '16GB RAM',
      '160GB SSD Disk Space',
      'Unlimited Domain',
      'Unlimited Bandwidth',
      'Unlimited Emails',
      'Unlimited Databases',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=8'
  },
  5: {
    title: 'UltimateGeek Cloud',
    discountPercent: 50,
    priceINR: 3100,
    priceUSD: 21.44,
    featureLabel: 'Most Popular',
    features: [
      '8 vCPU',
      '32GB RAM',
      '240GB SSD Disk Space',
      'Unlimited Domain',
      'Unlimited Bandwidth',
      'Unlimited Emails',
      'Unlimited Databases',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=8'
  }
}
export default cloudHosting
