import featureImage from '../../images/features/vps.webp'
const vpsFeatures = {
  title: 'Faster. Stronger. Dedicated.',
  subtitle:
    "Experience up to 3x faster hosting. Our team of pros takes care of the backend, and you don't need to worry about it.",
  featureImage: featureImage,
  features: [
    {
      name: 'Guaranteed 99.9% Uptime',
      desc: 'We handle the server uptime and monitoring 24/7 for you.'
    },
    {
      name: 'Superior Speed Performance',
      desc:
        'Take full advantage of the speed and power. No technical knowledge needed!'
    },
    {
      name: 'Powerful Control Panel',
      desc:
        'Enjoy the great speed and power with a simple control panel optimised for everyone'
    }
  ]
}

export default vpsFeatures
